* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
}

.header {
  align-items: center;
  background: white;
  box-shadow: 0 10px 100px rgb(0 0 0 / 10%);
  display: flex;
  justify-content: space-between;
  padding: 20px 50px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.mobile-header {
  align-items: center;
  background: white;
  box-shadow: 0 10px 100px rgb(0 0 0 / 10%);
  height: 105px;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  position: sticky;
  top: 0;
  width: 100vw;
  display: none;
}

/* //////// NAVIGATION //////// */

.nav {
  display: flex;
}

.nav__links {
  align-items: center;
  display: flex;
  list-style: none;
}

.nav__link {
  color: #333;
  display: inline-block;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 1.5rem 1.4rem;
  text-transform: uppercase;
  transition: color 0.3s;
}

/* .nav__link:link,
.nav__link:visited {
  border-bottom: 1px solid transparent;
  color: black;
  text-decoration: none;
  transition: all 0.3s ease;
}

.nav__link:hover,
.nav__link:active {
  color: #4287f5;
  transform: translateY(-3px);
} */

.nav__link:link {
  border-bottom: 1px solid transparent;
  color: black;
  text-decoration: none;
  transition: all 0.3s ease;
}

.nav__link:hover {
  color: #4287f5;
  transform: translateY(-3px);
}

.nav__link:active {
  color: green;
}

/* ////////////////////////////// */

/* ///////MOBILE NAV BAR //////// */

.mobile-nav {
  border: solid 1px #000000;
  position: absolute;
  width: fit-content;
  background-color: white;
  width: 100%;
  top: 62px;
  right: 0px;
}

.mobile-nav__links {
  position: relative;
  /* border: solid 1px blue; */
  list-style: none;
}

.mobile-nav__link {
  display: block;
  padding: 10px 10px;
  color: #333;
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.mobile-nav__link:link,
.mobile-nav__link:visited {
  text-decoration: none;
}

/* ////////////////////////////// */

.home {
  align-items: center;
  background-image: url("../public/images/home-background.png");
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.home__content {
  color: white;
  letter-spacing: 2px;
  text-align: center;
  width: 65vw;
}

.home__header {
  font-size: 3em;
  padding: 40px 50px;
  text-transform: uppercase;
}

.home__para {
  font-size: 1.5em;
  padding-bottom: 40px;
  word-wrap: normal;
}

.home__btn {
  border: none;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 700;
  padding: 20px 50px;
}

.name {
  font-size: 1.2em;
  font-weight: 700;
  letter-spacing: 2px;
}

.about-me {
  align-items: center;
  background-color: #f7f8fa;
  display: flex;
  flex-direction: column;
  height: 100vh;
  scroll-margin-top: 6.4em;
  width: 100vw;
}

.about-me__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.about-me__content {
  display: flex;
  line-height: 1.5;
  width: 70%;
}

.about-me__text {
  color: #737373;
  letter-spacing: 1px;
  padding: 30px;
  width: 50%;
  word-wrap: normal;
}

.about-me__skills {
  padding: 30px;
  width: 50%;
}

.about-me__skill {
  background-color: lightgray;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 10px;
  margin-right: 10px;
  flex-wrap: wrap;
}

.skills {
  display: flex;
  flex-wrap: wrap;
}

h2 {
  align-self: center;
  font-size: 2em;
  padding: 20px;
  width: fit-content;
}

h2:after {
  border-bottom: 3px solid #4287f5;
  content: "";
  display: block;
  padding-top: 20px;
  transform: translate(120%);
  width: 30%;
}

h3 {
  padding-bottom: 20px;
}

.icon {
  /* color: lightgray; */
  color: #000000;
  transition-duration: 300ms;
  font-size: 2em;
  margin-right: 10px;
}

.linkedin-icon:hover {
  color: #0077b5;
  background-color: #ffffff;
}

.github-icon:hover {
  color: #800080;
  background-color: #ffffff;
}

.projects {
  /* background-color: #f7f8fa; */
  scroll-margin-top: 6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.app__container {
  width: 75vw;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: */
}

.project-app {
  padding: 30px;
  width: 33%;
}

.app-img {
  height: auto;
  padding: 10px 0;
  width: 20vw;
}

.caption {
  color: black;
  width: 20vw;
}

.project-skills {
  display: flex;
  flex-wrap: wrap;
}

.game-music {
  scroll-margin-top: 8em;
  margin: auto;
  width: 70%;
}

.hidden {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .mobile-header {
    display: flex;
    z-index: 1000;
  }

  .header {
    display: none;
  }
  .home__header {
    font-size: 2em;
    padding: 5px;
  }

  .home__para {
    font-size: 1em;
    padding: 20px 5px;
  }

  .about-me {
    display: flex;

    height: fit-content;
    flex-direction: column;
  }

  .about-me__container {
    width: 100vw;
  }

  .about-me__content {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }

  .about-me__text {
    width: 100vw;
  }

  .about-me__skills {
    width: 100vw;
  }

  .about-me__skill {
    font-size: 0.7em;
  }

  .projects {
    /* background-color: #f7f8fa; */
    scroll-margin-top: 6em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .app__container {
    display: flex;
    flex-direction: column;
    width: 100vw;
  }
  .app-img {
    width: 100%;
  }

  .project-app {
    padding: 30px;
    width: 100%;
  }

  .project-skills {
    flex-wrap: nowrap;
  }

  .caption {
    display: flex;
    flex-direction: row;
  }

  .linkedin-icon {
    color: #0077b5;
    background-color: #ffffff;
  }

  .github-icon {
    color: #800080;
    background-color: #ffffff;
  }

  html {
    scroll-margin-bottom: 500px;
  }
}
